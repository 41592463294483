import React, { useEffect, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
const Home = React.lazy(() => import('./containers/Home'));
const Catmonials = React.lazy(() => import('./containers/Catmonials'));
const About = React.lazy(() => import('./containers/About'));
const FeedYourCat = React.lazy(() => import('./containers/FeedYourCat'));
const FurentingGuide = React.lazy(() => import('./containers/FurentingGuide'));
const FurentingGuideArticle = React.lazy(() => import('./containers/FurentingGuideArticle'));
const WhereToBuy = React.lazy(() => import('./containers/WhereToBuy'));
const FAQs = React.lazy(() => import('./containers/FAQs'));
const SamplingForm = React.lazy(() => import('./containers/SamplingForm'));
const SamplingFormSubmitted = React.lazy(() => import('./containers/SamplingFormSubmitted'));
const Contact = React.lazy(() => import('./containers/Contact'));
const DataPrivacy = React.lazy(() => import('./containers/DataPrivacy'));
const CorporateDataPrivacy = React.lazy(() => import('./containers/CorporateDataPrivacy'));
const NotFound = lazy(() => import('./containers/NotFound'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));

export default function AppRoutes(props) {
	const location = useLocation();
	useEffect(() => {		
		props.reactGA.send({hitType:"pageview", page: location.pathname});		
	}, [location]);
	
	return (
		<>			
			<React.Suspense fallback={<div />}>
				<ScrollToTop />		
			</React.Suspense>
			<Routes>
				<Route path="/" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<Home />
					</React.Suspense>
				} />
				<Route path="/catmonials" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<Catmonials />
					</React.Suspense>
				} />
				<Route path="/about" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<About />
					</React.Suspense>
				} />
				<Route path="/feedYourCat" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<FeedYourCat />
					</React.Suspense>
				} />								
				<Route path="/furentingGuide" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<FurentingGuide />
					</React.Suspense>
				} />
				<Route path="/furentingGuide/:article" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<FurentingGuideArticle />
					</React.Suspense>
				} />
				<Route path="/whereToBuy" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<WhereToBuy />
					</React.Suspense>
				} />
				<Route path="/faqs" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<FAQs />
					</React.Suspense>
				} />
				<Route path="/contact" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<Contact />
					</React.Suspense>
				} />
				<Route path="/dataPrivacy" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<DataPrivacy />
					</React.Suspense>
				} />
				<Route path="/corporateDataPrivacy" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<CorporateDataPrivacy />
					</React.Suspense>
				} />
				<Route path="/samplingForm" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<SamplingForm />
					</React.Suspense>
				} />
				<Route path="/samplingFormSubmitted" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<SamplingFormSubmitted />
					</React.Suspense>
				} />
				<Route path="/samplingFormSubmitted" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<NotFound />
					</React.Suspense>
				} />				
				<Route path="*" element={
					<React.Suspense fallback={<div><h3 className="preloader">Loading...</h3></div>}>
						<NotFound />
					</React.Suspense>
				} />
			</Routes>				
			{/*<Switch>				
				<Route exact path="/">					
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Home />					
					</Suspense>
				</Route>
				<Route exact path="/catmonials">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Catmonials />
					</Suspense>
				</Route>
				<Route exact path="/about">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<About />
					</Suspense>
				</Route>
				<Route exact path="/feedYourCat">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FeedYourCat />
					</Suspense>
				</Route>
				<Route exact path="/whereToBuy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<WhereToBuy />
					</Suspense>
				</Route>
				<Route exact path="/furentingGuide">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FurentingGuide />
					</Suspense>
				</Route>
				<Route exact path="/furentingGuide/:article">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FurentingGuideArticle />
					</Suspense>
				</Route>
				<Route exact path="/contact">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<Contact />
					</Suspense>
				</Route>
				<Route exact path="/faqs">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<FAQs />
					</Suspense>
				</Route>
				<Route exact path="/dataPrivacy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<DataPrivacy />
					</Suspense>
				</Route>
				<Route exact path="/corporateDataPrivacy">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<CorporateDataPrivacy />
					</Suspense>
				</Route>
				<Route exact path="/samplingForm">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<SamplingForm />
					</Suspense>
				</Route>
				<Route exact path="/samplingFormSubmitted">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<SamplingFormSubmitted />
					</Suspense>
				</Route>
				<Route exact path="/pageNotFound">
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<NotFound />
					</Suspense>
				</Route>
				<Route>
					<Suspense fallback={<div className="preloader"><h3>Loading...</h3></div>}>
						<NotFound />
					</Suspense>
				</Route>
			</Switch> */}						
		</>
	);
}