import React, { useState, useEffect, Suspense, lazy } from 'react';
import ReactGA from 'react-ga4';
import AppRoutes from './Routes';
import { AppContext } from './libs/contextLib';
import config from './config';
import Header from './containers/Header';
//const BuyNowButton = lazy(() => import('./components/BuyNowButton'));
const BuyNowButton = React.lazy(() => import('./components/BuyNowButton'));
//const Home = React.lazy(() => import('./containers/Home'));
const Footer = React.lazy(() => import('./containers/Footer'));
//const Footer = lazy(() => import('./containers/Footer'));
const TRACKING_ID = 'G-7LET3386HJ';
ReactGA.initialize(TRACKING_ID);

/*global FB*/ 

function App() { 
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [fbUser, setFBUser] = useState(null);
  const [fbAppId, setFBAppId] = useState(null);  

  useEffect(() => {              

    if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {      
      console.log('local');
      setFBAppId(config.social.localhost);            
    } else if (window.location.hostname === 'goodestpetfood.com') {
      //console.log('goodestpetfood.com')
      console.log('live');
      setFBAppId(config.social.FB);      
    } else {
      //console.log('beta');
      console.log('else');
      setFBAppId(config.social.beta);            
    }    

    getFBInfo();
    
        
    onLoad();
  }, [fbAppId]);

  async function getFBInfo() {
      
  }

  async function onLoad() {                    
    //console.log(loadFacebookSDK);
    loadFacebookSDK();
  } 

  function loadFacebookSDK() {

    window.fbAsyncInit = function() {        
        FB.init({
          appId      : fbAppId,
          cookie     : true,
          xfbml      : true,
          version    : 'v10.0'
        });        
    };        

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));   
  } 

  return (  
      <div className="App">      
      <AppContext.Provider value={{isAuthenticated, userHasAuthenticated, userFirstName, setUserFirstName, fbUser, setFBUser, fbAppId}}>
        <Header />
        <Suspense fallback={<div />}>
          <BuyNowButton />
        </Suspense>
        <AppRoutes reactGA={ReactGA} tmp={"Hello"} />
        <Suspense fallback={<div />}>
          <Footer />
        </Suspense>
      </AppContext.Provider>        
      </div>  
  );
}

export default App;
